import { HttpClient } from '@wix/yoshi-flow-editor';
import {
  getPostFeedPage,
  getPostFeedMetadataPage,
} from '@wix/ambassador-blog-frontend-adapter-public-v2-post-feed-page/http';
import type { Post } from '@wix/ambassador-blog-frontend-adapter-public-v2-post-page/types';
import {
  BLOG_HEADER_TOTAL_RESULTS,
  EXPERIMENTS,
  getWixDataCategoryId,
  getWixDataTagId,
  handleAggregatorResponseWithHeaders,
  isExperimentEnabled,
  postListWidgetStyleParams as params,
} from '@wix/communities-blog-client-common';
import { OOI_EXPERIMENTS } from '../../../experiments';
import setPosts from '../../common/actions/set-posts';
import { createPromisifiedAction } from '../../common/actions-promisifier/create-promisified-action';
import { getAppSettingsValue } from '../../common/selectors/app-settings-base-selectors';
import { getQueryLocale } from '../../common/selectors/locale-selectors';
import { getDemoPosts } from '../../common/services/demo-posts';
import getEnvironment from '../../common/services/get-environment';
import { getTotalResults } from '../../common/services/pagination';
import { normalizePostV3 } from '../../common/services/post-utils';
import timezoneService from '../../common/services/timezone';
import { getTimezone } from '../../common/store/basic-params/basic-params-selectors';
import {
  fetchCategoriesSuccess,
  handleCategoriesResponse,
} from '../../common/store/categories/fetch-categories';
import { getInstanceId } from '../../common/store/instance-values/instance-values-selectors';
import { setIsLoading } from '../../common/store/is-loading/is-loading-actions';
import { setPostCount } from '../../common/store/post-count/set-posts-count';
import { fetchTopology } from '../../common/store/topology/topology-actions';
import {
  fetchTranslationsSuccess,
  handleTranslationsResponse,
} from '../../common/store/translations/translations-actions';
import { AppState, WixCodeApi } from '../../common/types';
import { getPostListWidgetPageSize } from '../selectors/post-list-widget-page-size';
import { AggregatorRequest } from '../types';
import { PostListWidgetThunkAction } from '../types/post-list-widget-thunk-args';

interface FetchPostListRenderModelParams {
  aggregatorRequest: AggregatorRequest;
  state: AppState;
  page?: number;
  fields?: string[];
}

const fetchPostListRenderModel = ({
  aggregatorRequest,
  state,
  page,
  fields,
}: FetchPostListRenderModelParams) => {
  const postLimit = getPostListWidgetPageSize(state);
  const featuredOnly = getAppSettingsValue({
    state,
    key: `style.booleans.${params.postListWidgetIsFeatured.key}`,
  });
  const categoryId = getWixDataCategoryId(state);
  const tagId = getWixDataTagId(state);
  const language = getQueryLocale(state);
  const timezone = getTimezone(state) || timezoneService.timezone();

  return aggregatorRequest<any>(
    `/v1/post-list-widget/render-model?${[
      `timezone=${timezone}`,
      postLimit && `postLimit=${postLimit}`,
      featuredOnly && `featuredOnly=${featuredOnly}`,
      categoryId && `categoryId=${categoryId}`,
      tagId && `tagId=${tagId}`,
      language && `language=${language}`,
      page && `page=${page}`,
      fields && `fields=${fields.join(',')}`,
    ]
      .filter(Boolean)
      .join('&')}`,
    { throwOnInvalidJson: true },
  );
};

type FetchPostListFrontendAdapterParams = {
  state: AppState;
  page?: number;
  httpClient: HttpClient;
  includeInitialPageData: boolean;
  wixCodeApi: WixCodeApi;
};

const fetchPostListFrontendAdapter = async ({
  state,
  page = 1,
  httpClient,
  includeInitialPageData,
  wixCodeApi,
}: FetchPostListFrontendAdapterParams) => {
  const featuredOnly = getAppSettingsValue({
    state,
    key: `style.booleans.${params.postListWidgetIsFeatured.key}`,
  });
  const categoryId = getWixDataCategoryId(state);
  const tagId = getWixDataTagId(state);
  const language = getQueryLocale(state) ?? wixCodeApi.site.language;
  const pageSize = getPostListWidgetPageSize(state);

  const [response, metadataResponse] = await Promise.all([
    httpClient.request(
      getPostFeedPage({
        languageCode: language,
        page,
        pageSize,
        includeInitialPageData,
        postListWidgetOptions: {
          featuredOnly,
          categoryId,
          tagId,
        },
      }),
    ),
    httpClient.request(
      getPostFeedMetadataPage({
        page,
        pageSize,
        languageCode: language,
        postListWidgetOptions: { featuredOnly, categoryId, tagId },
      }),
    ),
  ]);

  return { response, metadataResponse };
};

const handlePostListPostsResponse =
  (postsResponse: any, { page = 1 } = {}): PostListWidgetThunkAction =>
  (dispatch, getState, { wixCodeApi, httpClient, aggregatorRequest }) => {
    dispatch(setIsLoading('postListPosts', undefined, true));

    return dispatch(handleAggregatorResponseWithHeaders(postsResponse))
      .then(async ({ body, headers }) => {
        if (!body.length && getEnvironment(wixCodeApi).isEditorSegment) {
          const state = getState();
          const fake = await getDemoPosts({
            httpClient,
            aggregatorRequest,
            getState,
            dispatch,
            wixCodeApi,
            query: {
              page,
              pageSize: getPostListWidgetPageSize(state),
              featuredOnly: getAppSettingsValue({
                state,
                key: `style.booleans.${params.postListWidgetIsFeatured.key}`,
              }),
              categoryId: getWixDataCategoryId(state),
              tagId: getWixDataTagId(state),
              language: getQueryLocale(state),
            },
          });

          body = fake.posts;
          headers = fake.headers as any;
        }

        dispatch(setPosts(body));
        dispatch(setPostCount(getTotalResults(headers)));
      })
      .then(() => dispatch(setIsLoading('postListPosts', undefined, false)))
      .catch(() => dispatch(setIsLoading('postListPosts', undefined, false)));
  };

export const fetchInitialData =
  (): PostListWidgetThunkAction =>
  async (
    dispatch,
    getState,
    { aggregatorRequest, compId, flowAPI, wixCodeApi, httpClient },
  ) => {
    const state = getState();
    const instanceId = getInstanceId(state);
    const warmupDataKey = `post-list-${compId}`;
    const shouldUseWarmupData =
      flowAPI.environment.isSSR &&
      isExperimentEnabled(state, EXPERIMENTS.USE_WARMUP_STATE_IN_POST_LIST);
    const shouldUseAdapter = isExperimentEnabled(
      state,
      OOI_EXPERIMENTS.POST_LIST_WIDGET_USE_ADAPTER,
    );

    let data: any;

    const getWarmupData = () => {
      try {
        const value = JSON.parse(
          wixCodeApi.window.warmupData.get(warmupDataKey),
        );
        return value;
      } catch {
        return undefined;
      }
    };

    if (shouldUseWarmupData) {
      data = await (shouldUseAdapter
        ? fetchPostListFrontendAdapter({
            state,
            page: 1,
            httpClient,
            includeInitialPageData: true,
            wixCodeApi,
          })
        : fetchPostListRenderModel({
            aggregatorRequest,
            state,
          }));
      wixCodeApi.window.warmupData.set(warmupDataKey, JSON.stringify(data));
    } else {
      data =
        getWarmupData() ??
        (await (shouldUseAdapter
          ? fetchPostListFrontendAdapter({
              state,
              page: 1,
              httpClient,
              includeInitialPageData: true,
              wixCodeApi,
            })
          : fetchPostListRenderModel({
              aggregatorRequest,
              state,
            })));
    }

    if (shouldUseAdapter) {
      const postFeedPage = data.response.data.postFeedPage;
      const postFeedMetadataPage =
        data.metadataResponse.data.postFeedMetadataPage;

      await dispatch(fetchTranslationsSuccess(postFeedPage.translations));

      if (postFeedPage.categories) {
        await dispatch(fetchCategoriesSuccess(postFeedPage.categories));
      }

      await dispatch(fetchTopology(instanceId));
      await dispatch(
        handlePostListPostsResponse({
          status: 200,
          body: postFeedPage.posts.posts.map((post: Post) =>
            normalizePostV3({
              ...post,
              metrics: postFeedMetadataPage.postMetrics[post.id!],
            }),
          ),
          headers: {
            [BLOG_HEADER_TOTAL_RESULTS]:
              postFeedPage.posts.pagingMetaData.total,
          },
        }),
      );
    } else {
      await dispatch(handleTranslationsResponse(data.translations));

      if (data.categories) {
        await dispatch(handleCategoriesResponse(data.categories));
      }

      await dispatch(fetchTopology(instanceId));
      await dispatch(handlePostListPostsResponse(data.posts));
    }
  };

export const fetchPostListPosts =
  (page?: number): PostListWidgetThunkAction =>
  async (dispatch, getState, { aggregatorRequest, httpClient, wixCodeApi }) => {
    const state = getState();
    const shouldUseAdapter = isExperimentEnabled(
      state,
      OOI_EXPERIMENTS.POST_LIST_WIDGET_USE_ADAPTER,
    );

    const data = await (shouldUseAdapter
      ? fetchPostListFrontendAdapter({
          state,
          page,
          httpClient,
          includeInitialPageData: false,
          wixCodeApi,
        })
      : fetchPostListRenderModel({
          aggregatorRequest,
          state,
          page,
          fields: ['posts'],
        }));

    if (shouldUseAdapter) {
      const postFeedPage = data.response.data.postFeedPage;
      const postFeedMetadataPage =
        data.metadataResponse.data.postFeedMetadataPage;

      await dispatch(
        handlePostListPostsResponse(
          {
            status: 200,
            body: postFeedPage.posts.posts.map((post: Post) =>
              normalizePostV3({
                ...post,
                metrics: postFeedMetadataPage.postMetrics[post.id!],
              }),
            ),
            headers: {
              [BLOG_HEADER_TOTAL_RESULTS]:
                postFeedPage.posts.pagingMetaData.total,
            },
          },
          { page },
        ),
      );
    } else {
      await dispatch(handlePostListPostsResponse(data.posts, { page }));
    }
  };

export const fetchPostListPostsPromisified = createPromisifiedAction(
  fetchPostListPosts,
  () => null,
  (response) => response.status,
);
